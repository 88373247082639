.user-picker {
    border: 1px solid;
    max-height: 202px;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 400px;
    margin: 0 auto;
}

.user-picker .MuiTableCell-root {
    border-bottom: 0;
}

.user-picker .user-avatar {
    padding: 0;
}

.user-picker .MuiFormControlLabel-root {
    margin-left: 0;
    width: 100%;
}

.user-picker .MuiCheckbox-root {
    padding: 8px 8px 8px 5px;
}

.user-picker .no-one {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    opacity: 0.5;
}

.user-picker table.user {
    width: 300px;
}

.user-picker td.name p {
    width: 120px;
    max-width: 120px;
    padding: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-picker td.icon {
    width: 30px;
    max-width: 30px;
    overflow: hidden;
    text-align: left;
}

.user-picker td.hours {
    width: 40px;
    max-width: 40px;
    overflow: hidden;
    text-align: right;
}

.user-picker td.seniority {
    width: 40px;
    max-width: 40px;
    overflow: hidden;
    text-align: right;
    white-space: nowrap;
}

.user-picker td.rate {
    width: 70px;
    max-width: 70px;
    overflow: hidden;
    text-align: right;
}

.user-picker td.icon img,
.user-picker td.icon svg {
    display: block;
    margin: auto 0;
}

.user-picker .bogus {
    text-decoration: line-through;
}

.user-picker .disabled img {
    -webkit-mask-image: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 255, 255, .4)));
}

.user-picker .disabled svg {
    -webkit-mask-image: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 255, 255, .2)));
}

.user-picker .disabled .MuiAvatar-colorDefault {
    background-color: rgba(100, 100, 100, .25);
}

.light .user-picker .overtime {
    color: rgb(192, 43, 36);
}

.light .user-picker .disabled .overtime {
    color: rgba(192, 43, 36, 0.48);
}

.dark .user-picker .overtime {
    color: rgb(255, 163, 156);
}

.dark .user-picker .disabled .overtime {
    color: rgba(255, 163, 156, 0.48);
}

.light .user-picker .would-overtime {
    color: rgb(200, 150, 0);
}

.light .user-picker .disabled .would-overtime {
    color: rgba(200, 150, 0, 0.48);
}

.dark .user-picker .would-overtime {
    color: rgb(250, 200, 50);
}

.dark .user-picker .disabled .would-overtime {
    color: rgba(250, 200, 50, 0.48);
}

.light .user-picker .no-overtime {
    color: rgb(40, 120, 40);
}

.light .user-picker .disabled .no-overtime {
    color: rgba(40, 120, 40, 0.48);
}

.dark .user-picker .no-overtime {
    color: rgb(90, 170, 90);
}

.dark .user-picker .disabled .no-overtime {
    color: rgba(90, 170, 90, 0.48);
}
