table.checkbox-icon {
    width: auto !important;
}

table.checkbox-icon td {
    border: none !important;
}

.checkbox-icon .MuiTableCell-body {
    color: inherit;
}

.checkbox-icon .checkbox svg {
    margin: auto 0;
    display: block;
    height: 24px;
    padding-right: 5px;
}
