.track-dialog .info {
  margin-bottom: 10px;
}

.track-dialog .actions {
  margin-top: -15px;
  margin-bottom: 1px;
}

.track-dialog .actions,
.track-dialog .info {
  text-align: center;
}

.track-dialog .MuiDivider-root {
  margin-left: -24px;
  margin-right: -24px;
}
