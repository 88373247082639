.time-off-event {
    margin: 0;
}

.time-off-event table {
    width: auto !important;
}

.time-off-event table td {
    border: none !important;
}

.time-off-event .MuiTableCell-body {
    color: inherit;
}

.time-off-event .thumb svg {
    padding-right: 5px;
}

.time-off-event .thumb svg {
    margin: auto 0;
    display: block;
    height: 28px;
}

.time-off-event .title {
    line-height: 28px;
}
