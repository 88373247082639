.admin-time-off-dialog .MuiDivider-root {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: 0;
}

.admin-time-off-dialog .MuiDialogActions-root.top-actions,
.admin-time-off-dialog .MuiDialogActions-root.bottom-actions {
    padding-bottom: 0;
}
