.mine-shift-dialog .info,
.mine-shift-dialog table.available-users {
  margin-top: 10px;
}

.mine-shift-dialog table th,
.mine-shift-dialog table td {
  border: none !important;
}

.mine-shift-dialog table.available-users {
  width: 100% !important;
}

.mine-shift-dialog .available-users {
  max-height: 202px;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.mine-shift-dialog .available-users .user-avatar {
  padding: 0;
}

.mine-shift-dialog .MuiDivider-root {
  margin-left: -24px;
  margin-right: -24px;
}

.mine-shift-dialog .MuiDialogActions-root {
  margin-top: 10px;
}

.mine-shift-dialog .buttons button {
  margin-left: 5px;
  margin-right: 5px;
}
