.time-zone-field {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.time-zone-field .details {
    position: relative;
    float: left;
    margin-left: 10px;
}
