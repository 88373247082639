.trade-shift-dialog .info {
  margin-top: 10px;
}

.trade-shift-dialog .user-avatar {
  margin-top: 20px;
}

.trade-shift-dialog .MuiDivider-root {
  margin-left: -24px;
  margin-right: -24px;
}
