.past-time-off-dialog .requester {
    margin-bottom: 10px;
}

.past-time-off-dialog .MuiDivider-root {
    margin-left: -24px;
    margin-right: -24px;
}

.past-time-off-dialog .MuiDialogActions-root {
    padding-bottom: 0;
}
