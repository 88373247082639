.report-attendance-dialog td.MuiTableCell-root {
    border-style: none !important;
}

.report-attendance-dialog table.dates {
    margin: 10px auto;
}

.report-attendance-dialog .title {
    margin-top: 10px;
}
