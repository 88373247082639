.user-list {
    border: 1px solid;
    max-height: 202px;
    overflow-x: hidden;
    overflow-y: auto;
}

.user-list .MuiTableCell-root {
    border-bottom: 0;
}

.user-list .user-avatar {
    padding: 8px 0 8px 8px;
}

.user-list .no-one {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.user-list td.name p {
    width: 156px;
    max-width: 156px;
    padding: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-list td.hours {
    min-width: 30px;
}

.user-list td.volunteer svg {
    display: block;
    margin: auto 0;
}

.light .user-list .overtime {
    color: rgb(192, 43, 36);
}

.dark .user-list .overtime {
    color: rgb(255, 163, 156);
}

.light .user-list .no-overtime {
    color: rgb(40, 120, 40);
}

.dark .user-list .no-overtime {
    color: rgb(90, 170, 90);
}
