.eula {
    max-width: 600px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
}

.eula ul {
    list-style: disc !important;
    margin-bottom: 16px;
}

.eula li {
    list-style-type: inherit !important;
    display: list-item;
}

.eula a,
.eula a:visited {
    cursor: pointer;
    color: #5FAFDC;
}

.eula .agree {
    margin-top: 20px;
}
