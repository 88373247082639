.open-shift-dialog .MuiDialogContent-root {
    padding-bottom: 20px;
}

.open-shift-dialog .MuiDivider-root {
    margin-left: -24px;
    margin-right: -24px;
}

.open-shift-dialog button svg {
    margin-right: 5px;
}
