.detailed-shift-event {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}

.detailed-shift-event .user-avatar-group {
    margin-bottom: 1px;
}

.detailed-shift-event .trade,
.detailed-shift-event .trade span {
    line-height: 24px;
    height: 24px;
    vertical-align: top;
}

.detailed-shift-event .trade svg {
    margin-right: 5px;
}
