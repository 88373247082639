.admin-shift-dialog .info {
  margin-top: 10px;
}

.admin-shift-dialog table.recurrence {
  width: auto !important;
}

.admin-shift-dialog table.openings {
  width: 100% !important;
  margin-top: -10px;
}

.admin-shift-dialog table.openings td.label {
  padding-right: 30px;
}

.admin-shift-dialog table.openings td.actions {
  width: 100%;
}

.admin-shift-dialog table th,
.admin-shift-dialog table td {
  border: none !important;
}

.admin-shift-dialog .recurrence .image {
  padding-right: 10px;
}

.admin-shift-dialog .recurrence .image svg {
  margin: auto 0;
  display: block;
  height: 28px;
}

.admin-shift-dialog .MuiDivider-root {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 0;
}

.admin-shift-dialog .MuiDialogActions-root.top-actions {
  padding-bottom: 0;
}

.admin-shift-dialog .info {
  margin-bottom: 10px;
}

.admin-shift-dialog .info .user-picker {
  margin-left: -12px;
  margin-right: -12px;
}

.mobile .admin-shift-dialog .info .user-picker {
  margin-left: 0;
  margin-right: 0;
}

.admin-shift-dialog button.notify svg {
  margin-right: 4px;
  margin-left: -4px;
}

.admin-shift-dialog .all {
  text-align: right;
}
