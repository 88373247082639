.users-page button.floating-share {
    bottom: 68px;
    right: 64px;
}

.wide .users-page button.floating-share {
    left: calc(50% - 54px);
}

.users-page button.floating-add {
    bottom: 68px;
    right: 8px;
}

.wide .users-page button.floating-add {
    left: calc(50% + 8px);
}
