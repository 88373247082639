.attendance-report-page .results {
    width: 300px;
    margin: 10px auto;
}

.attendance-report-page hr {
    max-width: 400px;
    margin: 30px auto;
}

.attendance-report-page .user-avatar td p {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attendance-report-page .result {
    cursor: pointer;
}

.attendance-report-page .info {
    margin-top: 30px;
}

.attendance-report-page .dates {
    margin: 20px auto;
}

.attendance-report-page button svg {
    margin-right: 5px;
}

.attendance-report-page .print-only {
    display: none;
}

.attendance-report-page .bogus {
    text-decoration: line-through;
}
