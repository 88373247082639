.my-attendance-page .results {
    width: 300px;
    margin: 10px auto;
}

.my-attendance-page .result {
    cursor: pointer;
}

.my-attendance-page .info {
    margin-top: 30px;
}
