.intro-item {
    width: 355px;
    height: 50px;
}

.intro-item .left {
    position: relative;
    float: left;
    height: 50px;
}

.intro-item .right {
    position: relative;
    float: right;
    height: 50px;
}

.intro-item .left .checkbox-icon {
    /*margin: auto 0;*/
    height: 50px;
}
