table.visibility-icon {
    width: auto !important;
}

table.visibility-icon td {
    border: none !important;
}

.visibility-icon .MuiTableCell-body {
    color: inherit;
}

.visibility-icon .visibility svg {
    margin: auto 0;
    display: block;
    height: 32px;
    padding-right: 10px;
}
