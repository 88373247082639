.intro-page .checklist,
.intro-page ul {
    margin: 0 auto;
    width: 355px;
}

.intro-page ul {
    list-style-type: disc !important;
    background-color: inherit;
}

.intro-page li {
    list-style-type: inherit !important;
    display: list-item;
}
