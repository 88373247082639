.summary-shift-event {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}

.summary-shift-event .open,
.summary-shift-event .trade,
.summary-shift-event .trade span {
    line-height: 24px;
    height: 24px;
    vertical-align: top;
}

.summary-shift-event .trade svg.trade {
    margin-right: 3px;
}

.summary-shift-event .trade svg.denied {
    margin-right: 5px;
}

.summary-shift-event .notes {
    margin-left: 4px;
}
