.attendance-page {
    margin-left: -15px;
    margin-right: -15px;
}

.attendance-page .title {
    text-align: left;
    padding-left: 5px;
}

.attendance-page .lists {
    margin-top: 20px;
}

.attendance-page .users {
    margin-bottom: 20px;
}

.attendance-page .footer {
    margin-top: 30px;
}

.attendance-page .off-users .title,
.attendance-page .off-users .chevron svg {
    cursor: pointer;
}

.attendance-page .lists {
    width: 100%;
}

.attendance-page .lists .came-in,
.attendance-page .lists .no-show {
    width: 49.5%;
    vertical-align: bottom;
}

.attendance-page .spacer {
    width: 1%;
}

.attendance-page .footer button {
    margin-left: 10px;
    margin-right: 10px;
}

.light .attendance-page .fc .fc-toolbar.fc-header-toolbar {
    background-color: #FFFFFF;
}

.dark .attendance-page .fc .fc-toolbar.fc-header-toolbar {
    background-color: #111111;
}

.attendance-page .fc .fc-toolbar.fc-header-toolbar {
    margin-top: -20px;
    margin-bottom: 7px;
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 4px;
    position: fixed;
    left: 0;
    z-index: 1;
    width: 100%;
}

.wide .attendance-page .fc .fc-toolbar.fc-header-toolbar {
    padding: 19px 8px 15px 10px;
}

.attendance-page .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attendance-page .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.attendance-page .fc .fc-toolbar.fc-header-toolbar .fc-today-button {
    margin-bottom: 2px;
}

.attendance-page .fc .fc-toolbar.fc-header-toolbar .fc-next-button {
    margin-right: 5px;
}

.wide .attendance-page .fc .fc-toolbar.fc-header-toolbar .fc-next-button {
    margin-right: 2px;
}

.attendance-page .fc .fc-view-harness {
    margin-top: 30px;
}

.wide .attendance-page .fc .fc-view-harness {
    margin-top: 50px;
}

.attendance-page .bogus {
    text-decoration: line-through;
}

.attendance-page .time {
    line-height: 24px;
    font-weight: bold;
}
