.time-off-page .calendar {
    position: fixed;
    top: 8px;
    bottom: 104px;
    left: 5px;
    right: 5px;
    overflow-x: hidden;
    overflow-y: auto;
}

.wide .time-off-page .calendar {
    top: 20px;
    bottom: 126px;
    left: 10px;
    right: 10px;
}

.time-off-page .spinner {
    position: fixed;
    top: 0;
    bottom: auto;
    left: 6px;
    z-index: 1200;
}

.wide .time-off-page .spinner {
    top: 14px;
    left: calc(50% - 25px);
}

.time-off-page .filter {
    position: fixed;
    top: auto;
    bottom: 55px;
    left: 5px;
    height: 56px;
    max-width: 275px;
    text-align: left;
}

.wide .time-off-page .filter {
    bottom: 60px;
    left: 10px;
    max-width: 300px;
}

.time-off-page .fc-toolbar.fc-header-toolbar {
    margin-bottom: 5px;
}

.wide .time-off-page .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
}

.time-off-page .fc-toolbar.fc-footer-toolbar {
    margin-top: 0;
}

.time-off-page .fc-footer-toolbar .fc-toolbar-title {
    height: 1.75em;
    font-size: 1.5em;
}

.time-off-page .fc-daygrid-event-harness {
    cursor: pointer;
}

.light .time-off-page .fc-view-harness {
    background-color: #FFFFFF;
}

.dark .time-off-page .fc-view-harness {
    background-color: #111111;
}

.dark .time-off-page .fc-day-today {
    background-color: #535040;
}

.time-off-page .fc-event.needs-attention {
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, .1), rgba(255, 255, 255, .2), rgba(255, 255, 255, .5), rgba(255, 255, 255, .75));
}
