.past-shift-dialog .MuiDivider-root {
    margin-left: -24px;
    margin-right: -24px;
}

.past-shift-dialog .MuiCheckbox-root {
    padding: 8px;
}

.past-shift-dialog .info {
    margin-top: 20px;
}

.past-shift-dialog .info .user-list {
    margin-left: -12px;
    margin-right: -12px;
}

.mobile .past-shift-dialog .info .user-list {
    margin-left: 0;
    margin-right: 0;
}

.past-shift-dialog .MuiDialogActions-root {
    padding-bottom: 0;
}
