.color-picker {
    width: 300px;
    margin: 0 auto;
    text-align: left;
    padding: 0 10px 10px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.color-picker .title {
    margin: 5px 0;
}

.dark .color-picker {
    border: 1px solid #757575;
}

.light .color-picker {
    border: 1px solid #E2E2E1;
}
