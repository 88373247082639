.shift-info .info,
.shift-info .description {
    margin-top: 10px;
}

.shift-info .description {
    border: 1px solid;
    padding: 5px 8px;
    max-height: 105px;
    overflow-x: hidden;
    overflow-y: auto;
}
